body {
	font-family: 'Courier Prime', monospace;
	font-size: 22px;
	background-color: black;
	margin: 0 1rem 0 1rem;
	line-height: 24px;
	padding: 0;
}

input {
	font-family: 'Courier Prime', monospace;
	font-size: 22px;
}

pre {
	font-family: 'Courier Prime', monospace;
	font-size: 22px;
}

a {
	/*color:#ecafc6;*/
	color:#73b0f1;
	border: none;
	text-decoration: underline dotted white;
}
